import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/browser';
import App from './App';

Sentry.init({dsn: "https://f45371c770f44bde8c926e2e2e7cf51b@o387824.ingest.sentry.io/5225054", release: "player@0.1.0"});

ReactDOM.render(
    <App />,
  document.getElementById('root')
);
