import React, {Component} from "react";
import Loader from "./Loader";
import "./scss/embed.css";


export interface ModalEmbedDetailProps {
    name: string,
    loaded: boolean
    imageIndex: number,
    current: string,
    next: string
}
export class ModalEmbedDetail extends Component<ModalEmbedDetailProps> {

    render() {
        let loaderElem = this.props.loaded ? null : (
            <Loader
                size="large"
                style={{
                    display: "block",
                    position: "absolute",
                    top: "calc(50% - 50px)",
                    left: "calc(50% - 50px)"
                }}
            />
        );

        return (
            <div id="modalEmbedPlayerImage">
                {loaderElem}
                <span id="modalImageCurrent" style={{backgroundImage: "url("+this.props.current+")"}} />
                <span id="modalImageNext"  style={{backgroundImage: "url("+this.props.next+")"}} />
            </div>
        )
    }

}
