import React, {Component} from 'react';
import {EmbedPlayer} from "./EmbedPlayer";
import * as axios from "axios";
import {WatchData} from "./interfaces";

interface AppProps {

}
interface AppState {
  data?: WatchData,
  embedKey?: string,
  watchKey?: string,
  watchId?: number
  type: "watch" | "embed" | null
}
export class App extends Component<AppProps, AppState> {

  constructor(props: AppProps) {
    super(props);
    this.state = {
      type: null
    }
  }

  componentDidMount(): void {
    const path = document.location.pathname.split('/');
    if (path.length < 3)
      return;

    const type = path[1];
    if (type === "embed" && path.length !== 3)
      return;

    if (type === "embed")
      this.fetchEmbedData(path[2]).catch(function(err){
        console.error(err)
      });

    if (type === "watch" && path.length !== 4)
      return;

  }


  async fetchEmbedData(embedKey: string) {

    const urlParts: string[] = [process.env.REACT_APP_API || 'https://api.positiveprime.com'];
    urlParts.push('embed');
    urlParts.push(embedKey)
    urlParts.push('watch');
    const url = urlParts.join('/');
    const fetch = await axios.default.post<WatchData>(url);
    this.setState({
      data: fetch.data,
      embedKey: embedKey,
      type: "embed"
    });
    return fetch.data;
  }

  render() {

    if (this.state.data && this.state.type === "embed") {
      return (
          <EmbedPlayer data={this.state.data} />
      );
    } else {
      return (
         <div />
      )
    }
  }
}

export default App;
