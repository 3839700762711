import React, {Component} from "react";
import Loader from "./Loader";
import "./scss/embed.css";

export interface EmbedDetailProps {
    name: string,
    progressBar: number,
    loaded: boolean
    imageIndex: number,
    current: string,
    next: string
}
export class EmbedDetail extends Component<EmbedDetailProps> {

  render() {

      let percent:number = this.props.progressBar ? this.props.progressBar : 0;
      if (percent > 100)
          percent = 100;

      let loaderElem = this.props.loaded ? null : (
          <Loader
              size="large"
              style={{
                  display: "block",
                  position: "absolute",
                  top: "calc(50% - 50px)",
                  left: "calc(50% - 50px)"
              }}
          />
      );

      return (
          <div id="mainEmbedPlayerImage">
              {loaderElem}

              <span id="mainImageCurrent" style={{backgroundImage: "url("+this.props.current+")"}} />
              <span id="mainImageNext" style={{backgroundImage: "url("+this.props.next+")"}} />
          </div>
      )
  }
}
