import React, {Component} from 'react';

// TODO: Refactor logic
//       1. Take in id for props since there maybe more than one spinner
//       2. Rename this file Spinner and css to spinner.
//       3. Custom spinner css class name will have pp-spinner as a name.
//             -> rename loader to pp-spinner-sm, pp-spinner-md, pp-spinner-lg, etc...

interface LoaderProps {
    size: string,
    id?: string,
    style: any
}
export class Loader extends Component<LoaderProps> {
    render() {
        let classList = 'loader'; // Default to the largest one

        switch (this.props.size) {
            case 'small':
                classList = 'loader-small';
                break;
            case 'large':
                classList = 'loader';
                break;
            case 'medium':
                classList = 'loader-medium';
                break;
            default:
                break;
        }

        const id = this.props.id || 'modelLoader';

        return <div id={id} className={classList} style={this.props.style} />;
    }
}

export default Loader;
